import axios from 'axios';

const AxiosAPI = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    validateStatus: (status: number) => status >= 200
})

AxiosAPI.interceptors.request.use((req: any) => {
    req.headers.authorization = `Bearer ${localStorage.getItem('token') || ''}`
    return req;
})

export default AxiosAPI