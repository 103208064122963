import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, AppBarProps, Box, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, styled, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginStatus from '../enums/login-status.enum';
import { useAuth } from '../services/AuthProvider';
import Routing from './routing/Routing';

const drawerWidth = 270;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
        marginLeft: `-${drawerWidth}px`,
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: 0,
        },
    }),
}));

const CustomAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const NavigationItems = ({ navigateMethod }: { navigateMethod: (path: string) => void }) => <>
    <DrawerHeader />
    <Divider />
    <List dense>
        <ListItem disablePadding>
            <ListItemButton onClick={() => navigateMethod('/applications/add')}>
                <ListItemText primary={<Typography variant='subtitle1' color="secondary.main">Add New Application</Typography>} secondary={<Typography variant='caption'>Add a New Application to Use</Typography>} />
            </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
            <ListItemButton onClick={() => navigateMethod('/applications/view')}>
                <ListItemText primary={<Typography variant='subtitle1' color="secondary.main">View Applications</Typography>} secondary={<Typography variant='caption'>View All Applications available</Typography>} />
            </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
            <ListItemButton onClick={() => navigateMethod('/applications/manage/search')}>
                <ListItemText primary={<Typography variant='subtitle1' color="secondary.main">Manage Application</Typography>} secondary={<Typography variant='caption'>Manage All Attributes of an Application</Typography>} />
            </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
            <ListItemButton onClick={() => navigateMethod('/users/manage')}>
                <ListItemText primary={<Typography variant='subtitle1' color="secondary.main">Manage Users</Typography>} secondary={<Typography variant='caption'>View accesses of Users across Applications</Typography>} />
            </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
            <ListItemButton onClick={() => navigateMethod('/users/manage/search')}>
                <ListItemText primary={<Typography variant='subtitle1' color="secondary.main">Manage Users for Application</Typography>} secondary={<Typography variant='caption'>Manage Various Attributes for Users of an Application</Typography>} />
            </ListItemButton>
        </ListItem>
        <Divider />
    </List>
</>

const Base = () => {
    const [open, setOpen] = React.useState(false)

    const { state: authState, logout, initialize } = useAuth()
    const navigate = useNavigate()

    const navigateTo = (path: string) => {
        navigate(path)
        setOpen(false)
    }

    const logoutUser = () => {
        setOpen(false)
        logout()
    }

    const toggleDrawer = () => {
        setOpen(!open)
    }

    React.useEffect(() => {
        initialize()
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <CustomAppBar position="fixed">
                <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start" sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color={"warning.main"} noWrap component="span" onClick={() => navigate('/')} sx={{
                        mr: 2, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', textDecoration: 'none', cursor: 'pointer'
                    }}>
                        SSO CONTROL PANEL
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {/* For Large Screens */}
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'inherit' } }}>
                        {authState.status == LoginStatus.LOGGED_IN && <Button variant="outlined" color="secondary" size="small" onClick={logoutUser}>LOGOUT</Button>}
                        {authState.status == LoginStatus.LOGGED_OUT && <Button variant="outlined" color="success" size="small" onClick={() => navigate('/login')}>LOGIN</Button>}
                    </Box>
                </Toolbar>
            </CustomAppBar>
            {/* For Small Screens */}
            <Drawer sx={{
                display: { xs: 'inherit', sm: 'inherit', md: 'none' },
                width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }} variant="temporary" anchor="left" open={open}>
                <NavigationItems navigateMethod={navigateTo} />
                <Box sx={{ flexGrow: 1 }} />
                <Divider />
                {authState.status == LoginStatus.LOGGED_IN && <ListItem disablePadding>
                    <ListItemButton onClick={logoutUser}>
                        <ListItemText primary={<Typography variant='h6' color="warning.main" align="center">LOGOUT</Typography>} />
                    </ListItemButton>
                </ListItem>}
                {authState.status == LoginStatus.LOGGED_OUT && <ListItem disablePadding>
                    <ListItemButton onClick={() => navigateTo('/login')}>
                        <ListItemText primary={<Typography variant='h6' color="success.main" align="center">LOGIN</Typography>} />
                    </ListItemButton>
                </ListItem>}
            </Drawer>
            {/* For Large Screens */}
            <Drawer sx={{
                display: { xs: 'none', sm: 'none', md: 'inherit' },
                width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }} variant="persistent" anchor="left" open={open}>
                <NavigationItems navigateMethod={navigate} />
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                <Routing />
            </Main>
        </Box>
    );
}

export default Base