import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { createContext, useContext, useReducer, useState } from "react"
import PromptDialogContextInfo from "../interfaces/prompt-dialog-context-info"

const PromptDialogContext = createContext<PromptDialogContextInfo>({ showPromptDialog: () => { } })

const PromptDialogProvider = ({ children }: any) => {

    const [input, setInput] = useState<string>('')

    const [state, dispatch] = useReducer
        (
            (previousState: any, action: { message: string, onConfirm: (input: string) => void, onCancel: () => void, open: boolean }) => {
                return action
            },
            { message: '', onConfirm: (input: string) => { }, onCancel: () => { }, open: false }
        )

    const showPromptDialog = (message: string, onConfirm: (input: string) => void, onCancel: () => void) => {
        dispatch({ message, onConfirm, onCancel, open: true })
    }

    const hidePromptDialog = () => {
        dispatch({ ...state, open: false })
    }

    return <PromptDialogContext.Provider value={{ showPromptDialog }}>{children}
        <Dialog open={state.open} onClose={hidePromptDialog} fullWidth>
            <DialogTitle>Input Needed</DialogTitle>
            <DialogContent>
                <TextField fullWidth variant="outlined" label={state.message} value={input} onChange={$e => setInput($e.target.value)} sx={{ mt: 2, mb: 2 }} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" size="small" onClick={() => { hidePromptDialog(); state.onCancel() }}>CANCEL</Button>
                <Button variant="outlined" color="warning" size="small" onClick={() => { hidePromptDialog(); state.onConfirm(input); setInput('') }}>CONFIRM</Button>
            </DialogActions>
        </Dialog>
    </PromptDialogContext.Provider>
}

export const usePromptDialog = () => {
    return useContext(PromptDialogContext);
}

export default PromptDialogProvider;