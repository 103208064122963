import { Lock as LockIcon, LockOpen as LockOpenIcon } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Container, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosAPI from "../../config/AxiosAPI";
import { useConfirmDialog } from "../../services/ConfirmDialogProvider";
import { usePromptDialog } from "../../services/PromptDialogProvider";
import { useSnackbar } from "../../services/SnackbarProvider";

const CustomPaper = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: `calc(100vw - 80px) !important`,
    }
}));

interface Column {
    label: '#' | 'Email ID' | 'Role' | 'Last Login Time Stamp' | 'Enabled' | 'Action';
    minWidth?: number;
    align?: 'right' | 'left' | 'center';
}

const columns: readonly Column[] = [
    { label: '#' },
    { label: 'Email ID' },
    { label: 'Role', align: 'center' },
    { label: 'Last Login Time Stamp', align: 'center' },
    { label: 'Enabled', align: 'center' },
    { label: 'Action', align: 'center' },
];

interface Data {
    _id: string
    email: string
    role: string
    lastLoginTimeStamp: string
    enabled: boolean
}

const ManageUsersForApplication = () => {

    const { showSnackbar } = useSnackbar()
    const { appId: searchAppId } = useParams()
    const { showConfirmDialog } = useConfirmDialog()
    const { showPromptDialog } = usePromptDialog()
    const navigate = useNavigate()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [appId, setAppId] = React.useState<string>(searchAppId == 'search' ? '' : (searchAppId || ''))
    const [appName, setAppName] = React.useState<string>('')
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [users, setUsers] = React.useState<Data[]>([])
    const [lock, setLock] = React.useState<boolean>(false)
    const [page, setPage] = React.useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)

    const getUsers = () => {
        setLoading(true)
        AxiosAPI.get('/cpanel/users?appId=' + appId)
            .then(response => {
                if (response.status == 200) {
                    let d = response.data.data
                    if (d.userRecords.length) {
                        setUsers(d.userRecords)
                        setAppName(d.appName)
                        setLock(true)
                    } else {
                        showSnackbar('No User found for this App ID', "warning")
                    }
                } else {
                    console.error(response.data.error)
                    showSnackbar(response.data.message, "error")
                }
                setLoading(false)
            }).catch(error => {
                if (!(error.name && error.name == 'AxiosError'))
                    console.error(error)
                setErrorMessage(error.message ? error.message : "Unknown Error")
                setLoading(false)
            })
    }

    const toggleUser = (id: string) => {
        showConfirmDialog('Are you sure to change access of this User for your App?', () => {
            setLoading(true)
            AxiosAPI.put('/cpanel/users?userId=' + id, { appId })
                .then(response => {
                    if (response.status == 200) {
                        showSnackbar(response.data.message, "success")
                        getUsers()
                    } else {
                        console.error(response.data.error)
                        setLoading(false)
                        showSnackbar(response.data.message, "error")
                    }
                }).catch(error => {
                    if (!(error.name && error.name == 'AxiosError'))
                        console.error(error)
                    setErrorMessage(error.message ? error.message : "Unknown Error")
                    setLoading(false)
                })
        }, () => { })
    }

    const changeRole = (id: string) => {
        showPromptDialog('Updated Role for the User', (role: string) => {
            setLoading(true)
            AxiosAPI.put('/cpanel/users/update-role?userId=' + id, { appId, role })
                .then(response => {
                    if (response.status == 200) {
                        showSnackbar(response.data.message, "success")
                        getUsers()
                    } else {
                        console.error(response.data.error)
                        setLoading(false)
                        showSnackbar(response.data.message, "error")
                    }
                }).catch(error => {
                    if (!(error.name && error.name == 'AxiosError'))
                        console.error(error)
                    setErrorMessage(error.message ? error.message : "Unknown Error")
                    setLoading(false)
                })
        }, () => { })
    }

    const resetApp = () => {
        setAppId('')
        setAppName('')
        setLock(false)
        setUsers([])
        if (searchAppId != 'search') {
            navigate('/users/manage/search')
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    React.useEffect(() => { if (searchAppId != 'search') { getUsers() } }, [])

    return <>
        <Grid container spacing={2}>
            <Grid item sm />
            <Grid item sm={5}>
                <Typography variant="h5" color={"primary.main"} marginBottom={2} marginTop={2} align="center">Manage Users for Application</Typography>

                {errorMessage && <Typography variant="subtitle2" color="error.main" marginBottom={2} marginTop={2} align="center">{errorMessage}</Typography>}

                <TextField fullWidth type="text" required id="application-id" label="App ID" placeholder="9efwer-fdger" sx={{ mb: 2 }} value={appId} onChange={$e => setAppId($e.target.value)} helperText={"Enter an App ID"} disabled={lock} />

                <Box sx={{ display: 'flex' }}>
                    <Button variant="outlined" color="success" disabled={lock} onClick={getUsers} sx={{ mb: 2 }}>SEARCH USERS</Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button variant="outlined" color="warning" disabled={!lock} onClick={getUsers} sx={{ mb: 2 }}>REFRESH</Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button variant="outlined" color="secondary" disabled={!lock} onClick={resetApp} sx={{ mb: 2 }}>RESET</Button>
                </Box>
                <div style={{ textAlign: 'center' }}>
                    {loading && <CircularProgress color="secondary" />}
                </div>
            </Grid>
            <Grid item sm />
        </Grid>
        {!loading && !!users.length && <Container>
            <Typography variant="subtitle1" color={"info.main"} marginBottom={2} marginTop={2} align="center"><em>Users for <strong>{appName}</strong></em></Typography>
            <CustomPaper elevation={6}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => <TableCell key={column.label} align={column.align}>
                                    {column.label}
                                </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((user: Data, index: number) => <TableRow hover tabIndex={-1} key={user._id}>
                                    <TableCell>
                                        {(index + 1)}
                                    </TableCell>
                                    <TableCell>
                                        {user.email}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip label={user.role} color={user.role === "ADMIN" ? "warning" : "success"} size="small" sx={{ fontWeight: 'bold' }} />
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.lastLoginTimeStamp}
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.enabled ? <LockOpenIcon color="success" /> : <LockIcon color="error" />}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="outlined" color="warning" size="small" onClick={() => changeRole(user._id)}>CHANGE ROLE</Button>
                                        &nbsp;&nbsp;
                                        <Button variant="outlined" color={user.enabled ? "error" : "success"} size="small" onClick={() => toggleUser(user._id)}>{user.enabled ? "DISABLE" : "ENABLE"}</Button>
                                    </TableCell>
                                </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </CustomPaper>
        </Container>}
    </>
}

export default ManageUsersForApplication