import { Lock as LockIcon, LockOpen as LockOpenIcon } from "@mui/icons-material";
import { Button, CircularProgress, Container, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import AxiosAPI from "../../config/AxiosAPI";
import { useSnackbar } from "../../services/SnackbarProvider";

const CustomPaper = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: `calc(100vw - 80px) !important`,
    }
}));

interface Column {
    label: '#' | 'Application ID' | 'Application Name' | 'Enabled' | 'Manage'
    align?: 'right' | 'left' | 'center';
}

const columns: readonly Column[] = [
    { label: '#' },
    { label: 'Application ID' },
    { label: 'Application Name' },
    { label: 'Enabled', align: 'center' },
    { label: 'Manage', align: 'center' }
];

interface Data {
    _id: string
    appId: string
    appName: string
    enabled: boolean
}

const ViewApplications = () => {
    
    const { showSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [apps, setApps] = React.useState<Data[]>([])
    const [page, setPage] = React.useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)

    const getApps = () => {
        setLoading(true)
        AxiosAPI.get('/cpanel/client')
            .then(response => {
                if (response.status == 200) {
                    setApps(response.data.data)
                    showSnackbar(response.data.message, "success")
                } else {
                    console.error(response.data.error)
                    showSnackbar(response.data.message, "error")
                }
                setLoading(false)
            }).catch(error => {
                if (!(error.name && error.name == 'AxiosError'))
                    console.error(error)
                setErrorMessage(error.message ? error.message : "Unknown Error")
                setLoading(false)
            })
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    React.useEffect(() => {
        getApps()
    }, [])

    return <>
        <Grid container spacing={2}>
            <Grid item sm />
            <Grid item sm={5}>
                <Typography variant="h5" color={"primary.main"} marginBottom={2} marginTop={2} align="center">View Applications</Typography>

                {errorMessage && <Typography variant="subtitle2" color="error.main" marginBottom={2} marginTop={2} align="center">{errorMessage}</Typography>}

                <div style={{ textAlign: 'center' }}>
                    {loading && <CircularProgress color="secondary" />}
                </div>
            </Grid>
            <Grid item sm />
        </Grid>
        {!loading && <Container>
            <CustomPaper elevation={6}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => <TableCell key={column.label} align={column.align}>
                                    {column.label}
                                </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {apps
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((app: Data, index: number) => <TableRow hover tabIndex={-1} key={app._id}>
                                    <TableCell>
                                        {(index + 1)}
                                    </TableCell>
                                    <TableCell>
                                        {app.appId}
                                    </TableCell>
                                    <TableCell>
                                        {app.appName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {app.enabled ? <LockOpenIcon color="success" /> : <LockIcon color="error" />}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="outlined" color="secondary" size="small" onClick={() => navigate('/applications/manage/' + app.appId)}>MANAGE APP</Button>&nbsp;&nbsp;
                                        <Button variant="outlined" color="info" size="small" onClick={() => navigate('/users/manage/' + app.appId)}>MANAGE APP USERS</Button>
                                    </TableCell>
                                </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={apps.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </CustomPaper>
        </Container>}
    </>
}

export default ViewApplications