import React from "react"
import { useNavigate } from "react-router-dom"
import LoginStatus from "../../enums/login-status.enum"
import { useAuth } from "../../services/AuthProvider"
import { useSnackbar } from "../../services/SnackbarProvider"

const AuthGuard = ({ component: UIComponent }: { component: React.FC }) => {

    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar()
    const { state } = useAuth()

    React.useEffect(() => {
        if (state.status == LoginStatus.LOGGED_OUT) {
            showSnackbar('Please Login to Continue!', "warning")
            navigate('/login')
        }
    }, [state.status])

    return state.status == LoginStatus.LOGGED_IN ? <UIComponent /> : <></>

}

export default AuthGuard