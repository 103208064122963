import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { AxiosResponse } from "axios"
import React from "react"
import { useSnackbar } from "../../services/SnackbarProvider"
import useAddNewApplicationMutation from "../../services/useAddNewApplication"

const AddNewApplication = () => {

    const { showSnackbar } = useSnackbar()

    const [validData, setValidData] = React.useState<number>(-1)
    const [appInfo, setAppInfo] = React.useState<{ appName: string, redirectUrl: string }>({ appName: '', redirectUrl: '' })
    const [errorInfo, setErrorInfo] = React.useState<{ appName: string, redirectUrl: string }>({ appName: '', redirectUrl: '' })

    const { addNewApplicationMutate, isAddNewApplicationError, isAddNewApplicationLoading, addNewApplicationError } = useAddNewApplicationMutation((data: AxiosResponse<any, any>) => {
        if (data.status == 200) {
            showSnackbar(data.data.message, "success")
            downloadAppInfoAsJSON(appInfo.appName, data.data.appId, data.data.appSecret, appInfo.redirectUrl)
            setAppInfo({ appName: '', redirectUrl: '' })
        } else {
            showSnackbar(data.data.message, "error")
            console.error(data.data)
        }
    })

    const addNewApplication = () => {
        let e = 1, msgs = { appName: '', redirectUrl: '' }
        if (appInfo.appName.trim() == '') {
            msgs.appName = 'App Name is required!'
            e = 0
        } else if (!/^[A-Za-z ]+$/gm.test(appInfo.appName.trim())) {
            msgs.appName = 'App Name can only have Alpbahets or Spaces!'
            e = 0
        } else {
            msgs.appName = ''
        }
        if (appInfo.redirectUrl.trim() == '') {
            msgs.redirectUrl = 'Redirect URL is required!'
            e = 0
        } else {
            try {
                Boolean(new URL(appInfo.redirectUrl.trim()));
                msgs.redirectUrl = ''
            }
            catch (ex) {
                msgs.redirectUrl = 'Redirect URL is not Valid!'
                e = 0
            }
        }
        setValidData(e)
        setErrorInfo(msgs)
    }

    const downloadAppInfoAsJSON = (appName: string, appId: string, appSecret: string, redirectUrl: string) => {
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify({ appName, appId, appSecret, redirectUrl })));
        downloadAnchorNode.setAttribute("download", appName + ".json");
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    React.useEffect(() => {
        if (validData == 1)
            addNewApplicationMutate(appInfo)
    }, [validData])

    return <Grid container spacing={2}>
        <Grid item sm />
        <Grid item sm={5}>
            <Typography variant="h5" color={"primary.main"} marginBottom={2} marginTop={2} align="center">Add New Application</Typography>
            <div style={{ textAlign: 'center' }}>
                {isAddNewApplicationLoading && <CircularProgress color="secondary" />}
                <Typography variant="subtitle1" color={"warning.main"} marginBottom={2} marginTop={2} align="center">A JSON file with credentials of the App would be Downloaded on your System once you Register.</Typography>
            </div>
            <br />
            {!isAddNewApplicationLoading && <>
                <TextField fullWidth type="text" required id="application-name" label="Application Name" placeholder="Some App Name" sx={{ mb: 2 }} onChange={$e => setAppInfo({ ...appInfo, appName: $e.target.value })} error={validData == 0 && !!errorInfo.appName} helperText={errorInfo.appName} />
                <TextField fullWidth type="text" required id="redirect-url" label="Redirect URL" placeholder="https://example.com/resource" sx={{ mb: 2 }} onChange={$e => setAppInfo({ ...appInfo, redirectUrl: $e.target.value })} error={validData == 0 && !!errorInfo.redirectUrl} helperText={errorInfo.redirectUrl} />
                <Button variant="outlined" color="success" fullWidth size="large" onClick={addNewApplication}><i className='fas fa-sign-in-alt'></i> REGISTER APP</Button>
            </>}
            {isAddNewApplicationError && <Typography variant="subtitle2" color="error.main" marginBottom={2} marginTop={2} align="center">{addNewApplicationError instanceof Error && addNewApplicationError.message}</Typography>}
        </Grid>
        <Grid item sm />
    </Grid>
}

export default AddNewApplication