import { Alert, Snackbar } from "@mui/material"
import { createContext, useContext, useReducer } from "react"
import SnackbarContextInfo from "../interfaces/snackbar-context-info"

const SnackbarContext = createContext<SnackbarContextInfo>({ showSnackbar: () => { } })

const SnackbarProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer
        (
            (previousState: any, action: { message: string, severity: 'success' | 'info' | 'warning' | 'error', open: boolean }) => {
                return action
            },
            { message: '', severity: 'success', open: false }
        )

    const showSnackbar = (message: string, severity: 'success' | 'info' | 'warning' | 'error') => {
        dispatch({ message, severity, open: true })
    }

    const hideSnackBar = () => {
        dispatch({ ...state, open: false })
    }

    return <SnackbarContext.Provider value={{ showSnackbar }}>{children}
        <Snackbar open={state.open} autoHideDuration={3000} onClose={hideSnackBar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert severity={state.severity} variant="filled" sx={{ width: '100%' }}>
                {state.message}
            </Alert>
        </Snackbar>
    </SnackbarContext.Provider>
}

export const useSnackbar = () => {
    return useContext(SnackbarContext);
}

export default SnackbarProvider;