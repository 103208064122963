import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { createContext, useContext, useReducer } from "react"
import ConfirmDialogContextInfo from "../interfaces/confirm-dialog-context-info"

const ConfirmDialogContext = createContext<ConfirmDialogContextInfo>({ showConfirmDialog: () => { } })

const ConfirmDialogProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer
        (
            (previousState: any, action: { message: string, onConfirmYes: () => void, onConfirmNo: () => void, open: boolean }) => {
                return action
            },
            { message: '', onConfirmYes: () => { }, onConfirmNo: () => { }, open: false }
        )

    const showConfirmDialog = (message: string, onConfirmYes: () => void, onConfirmNo: () => void) => {
        dispatch({ message, onConfirmYes, onConfirmNo, open: true })
    }

    const hideConfirmDialog = () => {
        dispatch({ ...state, open: false })
    }

    return <ConfirmDialogContext.Provider value={{ showConfirmDialog }}>{children}
        <Dialog open={state.open} onClose={hideConfirmDialog} fullWidth>
            <DialogTitle>Please Confirm!</DialogTitle>
            <DialogContent>
                <DialogContentText>{state.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" size="small" onClick={() => { hideConfirmDialog(); state.onConfirmNo() }}>No</Button>
                <Button variant="outlined" color="warning" size="small" onClick={() => { hideConfirmDialog(); state.onConfirmYes() }}>Yes</Button>
            </DialogActions>
        </Dialog>
    </ConfirmDialogContext.Provider>
}

export const useConfirmDialog = () => {
    return useContext(ConfirmDialogContext);
}

export default ConfirmDialogProvider;