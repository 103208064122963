import { useMutation } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import AxiosAPI from "../config/AxiosAPI"

const verifyOTP = ({ otp, otpId }: { otp: number, otpId: number }) => {
    return AxiosAPI.post('/auth/verify', { otp, otpId })
}

const useVerifyOTPMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(verifyOTP, {
        onSuccess
    })

    return { verifyOTPMutate: mutate, verifyOTPData: data, isVerifyOTPError: isError, isVerifyOTPLoading: isLoading, verifyOTPError: error }
}

export default useVerifyOTPMutation