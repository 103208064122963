import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material"
import { AxiosResponse } from "axios"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import AxiosAPI from "../../config/AxiosAPI"
import { useConfirmDialog } from "../../services/ConfirmDialogProvider"
import { useSnackbar } from "../../services/SnackbarProvider"

const DEFAULT_APP_INFO = { appName: '', redirectUrl: '', sessionLimit: '', enabled: false, isRestricted: false, allowedUsers: [] }

const ManageApplication = () => {

    const { showSnackbar } = useSnackbar()
    const { showConfirmDialog } = useConfirmDialog()
    const { appId: searchAppId } = useParams()
    const navigate = useNavigate()

    const [appId, setAppId] = React.useState<string>(searchAppId == 'search' ? '' : (searchAppId || ''))
    const [userEmails, setUserEmails] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)
    const [lock, setLock] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [validData, setValidData] = React.useState<number>(-1)
    const [appInfo, setAppInfo] = React.useState<any>(DEFAULT_APP_INFO)
    const [errorInfo, setErrorInfo] = React.useState<{ appName: string, redirectUrl: string, sessionLimit: string }>({ appName: '', redirectUrl: '', sessionLimit: '' })

    const updateApp = () => {
        setLoading(true)
        AxiosAPI.put('/cpanel/client?appId=' + appId, appInfo)
            .then(response => {
                if (response.status == 200) {
                    showSnackbar(response.data.message, "success")
                    setValidData(-1)
                    getApp()
                } else {
                    console.error(response.data.error)
                    showSnackbar(response.data.message, "error")
                    setLoading(false)
                }
            }).catch(error => {
                if (!(error.name && error.name == 'AxiosError'))
                    console.error(error)
                setErrorMessage(error.message ? error.message : "Unknown Error")
                setLoading(false)
            })
    }

    const getApp = () => {
        setLoading(true)
        AxiosAPI.get('/cpanel/client?appId=' + appId)
            .then((response: AxiosResponse<any, any>) => {
                if (response.status == 200) {
                    let d = response.data.data
                    if (d) {
                        delete d.appId
                        setLock(true)
                        setAppInfo(d)
                        setUserEmails(d.allowedUsers.join(","))
                    } else {
                        showSnackbar('No App Found with this App ID', "warning")
                    }
                } else {
                    console.error(response.data.error)
                    showSnackbar(response.data.message, "error")
                }
                setLoading(false)
            }).catch(error => {
                if (!(error.name && error.name == 'AxiosError'))
                    console.error(error)
                setErrorMessage(error.message ? error.message : "Unknown Error")
                setLoading(false)
            })
    }

    const deleteApp = () => {
        showConfirmDialog('Are you sure to delete this App?', () => {
            setLoading(true)
            AxiosAPI.delete('/cpanel/client?appId=' + appId)
                .then(response => {
                    if (response.status == 200) {
                        showSnackbar(response.data.message, "success")
                        resetApp()
                    } else {
                        console.error(response.data.error)
                        showSnackbar(response.data.message, "error")
                    }
                    setLoading(false)
                }).catch(error => {
                    if (!(error.name && error.name == 'AxiosError'))
                        console.error(error)
                    setErrorMessage(error.message ? error.message : "Unknown Error")
                    setLoading(false)
                })
        }, () => { })
    }

    const resetApp = () => {
        setAppId('')
        setAppInfo(DEFAULT_APP_INFO)
        setLock(false)
        if (searchAppId != 'search') {
            navigate('/applications/manage/search')
        }
    }

    React.useEffect(() => { setAppInfo({ ...appInfo, allowedUsers: userEmails.split(",") }) }, [userEmails])

    React.useEffect(() => { if (searchAppId != 'search') { getApp() } }, [])

    const saveConfigs = () => {
        let e = 1, msgs = { appName: '', redirectUrl: '', sessionLimit: '' }
        if (appInfo.appName.trim() == '') {
            msgs.appName = 'App Name is required!'
            e = 0
        } else if (!/^[A-Za-z ]+$/gm.test(appInfo.appName.trim())) {
            msgs.appName = 'App Name can only have Alpbahets or Spaces!'
            e = 0
        } else {
            msgs.appName = ''
        }
        if (appInfo.redirectUrl.trim() == '') {
            msgs.redirectUrl = 'Redirect URL is required!'
            e = 0
        } else if (/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(appInfo.redirectUrl.trim())) {
            msgs.redirectUrl = 'Redirect URL is not Valid!'
            e = 0
        } else {
            msgs.redirectUrl = ''
        }
        if ((appInfo.sessionLimit + '').trim() == '') {
            msgs.sessionLimit = 'Session Limit is required!'
            e = 0
        } else if (!/^[0-9]+$/gm.test((appInfo.sessionLimit + '').trim())) {
            msgs.sessionLimit = 'Session Limit can only have digits!'
            e = 0
        } else {
            msgs.sessionLimit = ''
        }
        setValidData(e)
        setErrorInfo(msgs)
    }

    React.useEffect(() => { if (validData == 1) updateApp() }, [validData])

    return <Grid container spacing={2}>
        <Grid item sm />
        <Grid item sm={5}>
            <Typography variant="h5" color={"primary.main"} marginBottom={2} marginTop={2} align="center">Manage Application</Typography>

            {errorMessage && <Typography variant="subtitle2" color="error.main" marginBottom={2} marginTop={2} align="center">{errorMessage}</Typography>}

            <TextField fullWidth type="text" required id="application-id" label="App ID" placeholder="9efwer-fdger" sx={{ mb: 2 }} value={appId} onChange={$e => setAppId($e.target.value)} helperText={"Enter an App ID"} disabled={lock} />

            <Box sx={{ display: 'flex' }}>
                <Button variant="outlined" color="success" disabled={lock} onClick={getApp} sx={{ mb: 2 }}>SEARCH APP</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="outlined" color="warning" disabled={!lock} onClick={getApp} sx={{ mb: 2 }}>REFRESH</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="outlined" color="secondary" disabled={!lock} onClick={resetApp} sx={{ mb: 2 }}>RESET</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="outlined" color="error" disabled={!lock} onClick={deleteApp} sx={{ mb: 2 }}>DELETE APP</Button>
            </Box>
            <div style={{ textAlign: 'center' }}>
                {loading && <CircularProgress color="secondary" />}
            </div>
            {!loading && <>
                <TextField fullWidth type="text" required id="application-name" label="Application Name" placeholder="Some App Name" sx={{ mb: 2 }} value={appInfo.appName} onChange={$e => setAppInfo({ ...appInfo, appName: $e.target.value })} error={validData == 0 && !!errorInfo.appName} helperText={errorInfo.appName} />

                <TextField fullWidth type="text" required id="redirect-url" label="Redirect URL" placeholder="https://example.com/resource" sx={{ mb: 2 }} value={appInfo.redirectUrl} onChange={$e => setAppInfo({ ...appInfo, redirectUrl: $e.target.value })} error={validData == 0 && !!errorInfo.redirectUrl} helperText={errorInfo.redirectUrl} />

                <TextField fullWidth type="number" required id="session-limit" label="Session Limit (Seconds)" placeholder="900" sx={{ mb: 2 }} value={appInfo.sessionLimit} onChange={$e => setAppInfo({ ...appInfo, sessionLimit: $e.target.value })} error={validData == 0 && !!errorInfo.sessionLimit} helperText={errorInfo.sessionLimit} />

                <FormGroup sx={{ mb: 2 }}>
                    <FormControlLabel control={<Checkbox checked={appInfo.enabled} onChange={$e => setAppInfo({ ...appInfo, enabled: $e.target.checked })} />} label="Enabled" />
                    <FormControlLabel control={<Checkbox checked={appInfo.isRestricted} onChange={$e => setAppInfo({ ...appInfo, isRestricted: $e.target.checked })} />} label="Restricted" />
                </FormGroup>

                {appInfo.isRestricted && <TextField fullWidth multiline type="text" required id="emails" label="Allowed User Emails" placeholder="900" sx={{ mb: 2 }} value={userEmails} onChange={$e => setUserEmails($e.target.value)} helperText={"Email IDs separated by comma(,)"} />}

                <Button variant="outlined" color="success" onClick={saveConfigs}>SAVE CHANGES</Button>
            </>}
        </Grid>
        <Grid item sm />
    </Grid>
}

export default ManageApplication