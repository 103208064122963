import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import Base from './components/Base';
import AuthProvider from './services/AuthProvider';
import ConfirmDialogProvider from './services/ConfirmDialogProvider';
import PromptDialogProvider from './services/PromptDialogProvider';
import SnackbarProvider from './services/SnackbarProvider';

const queryClient = new QueryClient()

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const App = () => {
	return <ThemeProvider theme={darkTheme}>
		<CssBaseline />
		<QueryClientProvider client={queryClient}>
			<PromptDialogProvider>
				<ConfirmDialogProvider>
					<SnackbarProvider>
						<AuthProvider>
							<BrowserRouter>
								<Base />
							</BrowserRouter>
						</AuthProvider>
					</SnackbarProvider>
				</ConfirmDialogProvider>
			</PromptDialogProvider>
		</QueryClientProvider>
	</ThemeProvider>
}

export default App;
