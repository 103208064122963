import { useMutation } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import AxiosAPI from "../config/AxiosAPI"

const addNewApplication = ({ appName, redirectUrl }: { appName: string, redirectUrl: string }) => {
    return AxiosAPI.post('/cpanel/client', { appName, redirectUrl })
}

const useAddNewApplicationMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(addNewApplication, {
        onSuccess
    })

    return { addNewApplicationMutate: mutate, addNewApplicationData: data, isAddNewApplicationError: isError, isAddNewApplicationLoading: isLoading, addNewApplicationError: error }
}

export default useAddNewApplicationMutation