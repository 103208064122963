import { useMutation } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import AxiosAPI from "../config/AxiosAPI"

const login = ({ username, password }: { username: string, password: string }) => {
    return AxiosAPI.post('/auth/login', { username, password })
}

const useLoginMutation = (onSuccess: (data: AxiosResponse<any, any>) => void | Promise<unknown>) => {
    const { mutate, data, isError, isLoading, error } = useMutation(login, {
        onSuccess
    })

    return { loginMutate: mutate, loginData: data, isLoginError: isError, isLoginLoading: isLoading, loginError: error }
}

export default useLoginMutation