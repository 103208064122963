import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { AxiosResponse } from "axios"
import React from "react"
import { useNavigate } from "react-router-dom"
import LoginFlow from "../../enums/login-flow.enum"
import LoginStatus from "../../enums/login-status.enum"
import { useAuth } from "../../services/AuthProvider"
import { useSnackbar } from "../../services/SnackbarProvider"
import useLoginMutation from "../../services/useLogin"
import useVerifyOTPMutation from "../../services/useVerifyOTP"

const Login = () => {

    const { state: authState, login } = useAuth()
    const { showSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const [stage, setStage] = React.useState<LoginFlow>(LoginFlow.BEFORE_OTP_SENT)

    const [username, setUsername] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [otp, setOTP] = React.useState<string>('')
    const [otpId, setOTPID] = React.useState<string>('')
    const [otpExpiryMinutes, setOTPExpiryMinutes] = React.useState<string>('')

    const { loginMutate, isLoginError, isLoginLoading, loginError } = useLoginMutation((data: AxiosResponse<any, any>) => {
        if (data.status == 200) {
            showSnackbar(data.data.message, 'success')
            setOTPID(data.data.otpId)
            setOTPExpiryMinutes(`${data.data.otpExpiryMinutes}`)
            setStage(LoginFlow.BEFORE_VERIFY_OTP)
        } else {
            console.error(data.data)
            showSnackbar(data.data.message, 'error')
        }
    })

    const loginUser = () => {
        loginMutate({ username, password })
    }

    const { verifyOTPMutate, isVerifyOTPError, isVerifyOTPLoading, verifyOTPError } = useVerifyOTPMutation((data: AxiosResponse<any, any>) => {
        if (data.status == 200) {
            showSnackbar(data.data.message, "success")
            setStage(LoginFlow.AFTER_LOGIN)
            login(data.data.token)
            navigate('/')
        } else {
            showSnackbar(data.data.message, "error")
            console.error(data.data)
        }
    })

    const verifyOTP = () => {
        verifyOTPMutate({ otp: parseInt(otp), otpId: parseInt(otpId) })
    }

    React.useEffect(() => {
        if (authState.status == LoginStatus.LOGGED_IN) {
            navigate('/')
        }
    }, [])

    return <Grid container spacing={2}>
        <Grid item sm />
        <Grid item sm={3}>
            <Typography variant="h4" color={"info.main"} marginBottom={2} marginTop={2} align="center">LOGIN</Typography>
            <div style={{ textAlign: 'center' }}>
                {(isLoginLoading || isVerifyOTPLoading) && <CircularProgress color="secondary" />}
            </div>
            {!isLoginLoading && stage == LoginFlow.BEFORE_OTP_SENT && <>
                <TextField fullWidth type="text" required id="username" label="Username" placeholder="crazy@56" sx={{ mb: 2 }} onChange={$e => setUsername($e.target.value)} />
                <TextField fullWidth type="password" required id="password" label="Password" placeholder="******" sx={{ mb: 2 }} onChange={$e => setPassword($e.target.value)} />
                <Button variant="outlined" color="primary" fullWidth size="large" onClick={loginUser}><i className='fas fa-sign-in-alt'></i> SEND OTP</Button>
            </>}
            {!isVerifyOTPLoading && stage == LoginFlow.BEFORE_VERIFY_OTP && <>
                <Typography variant="subtitle1" color="warning.main" marginBottom={2} marginTop={2} align="center">OTP ID: {otpId}<br />Validity: {otpExpiryMinutes} Minutes</Typography>
                <Typography variant="subtitle2" color="success.main" marginBottom={2} marginTop={2} align="center">An OTP has been sent via Email. <strong>Please verify the OTP ID before entering.</strong></Typography>
                <TextField fullWidth type="number" required id="otp" label="8-Digit OTP" placeholder="12345678" sx={{ mb: 2 }} onChange={$e => setOTP($e.target.value)} />
                <Button variant="outlined" color="success" fullWidth size="large" onClick={verifyOTP}><i className='fas fa-sign-in-alt'></i> VERIFY OTP & LOGIN</Button>
            </>}
            {isLoginError && <Typography variant="subtitle2" color="error.main" marginBottom={2} marginTop={2} align="center">{loginError instanceof Error && loginError.message}</Typography>}
            {isVerifyOTPError && <Typography variant="subtitle2" color="error.main" marginBottom={2} marginTop={2} align="center">{verifyOTPError instanceof Error && verifyOTPError.message}</Typography>}
        </Grid>
        <Grid item sm />
    </Grid>
}

export default Login