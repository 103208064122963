import { Button, Chip, CircularProgress, Container, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React from "react";
import AxiosAPI from "../../config/AxiosAPI";
import { useConfirmDialog } from "../../services/ConfirmDialogProvider";
import { useSnackbar } from "../../services/SnackbarProvider";

const CustomPaper = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: `calc(100vw - 80px) !important`,
    }
}));

const ManageUsers = () => {

    const { showSnackbar } = useSnackbar()
    const { showConfirmDialog } = useConfirmDialog()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [users, setUsers] = React.useState<any>()
    const [page, setPage] = React.useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)

    const getApps = () => {
        setLoading(true)
        AxiosAPI.get('/cpanel/users')
            .then(response => {
                if (response.status == 200) {
                    const usersResponse = response.data.data
                    setUsers(usersResponse)
                } else {
                    console.error(response.data.error)
                    showSnackbar(response.data.message, "error")
                }
                setLoading(false)
            }).catch(error => {
                if (!(error.name && error.name == 'AxiosError'))
                    console.error(error)
                setErrorMessage(error.message ? error.message : "Unknown Error")
                setLoading(false)
            })
    }

    const requestMFARenewal = (id: string, email: string) => {
        showConfirmDialog(`Are you sure to Request Renewal for the User [${email}]? This action can't be undone. Once requested, User has to re-register for MFA to login.`, () => {
            setLoading(true)
            AxiosAPI.put(`/cpanel/users/renew-mfa?userId=${id}`)
                .then(response => {
                    if (response.status == 200) {
                        getApps()
                        showSnackbar(response.data.message, "success")
                    } else {
                        console.error(response.data.error)
                        showSnackbar(response.data.message, "error")
                        setLoading(false)
                    }
                }).catch(error => {
                    if (!(error.name && error.name == 'AxiosError'))
                        console.error(error)
                    setErrorMessage(error.message ? error.message : "Unknown Error")
                    setLoading(false)
                })
        }, () => { })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const getChipColor = (value: number) => {
        switch (value) {
            case 1: return "info"
            case -1: return "warning"
            case 0: return "error"
            default: return "secondary"
        }
    }

    const getRenewalButtonText = (value: number) => {
        switch (value) {
            case 1: return "Request MFA Renewal"
            case -1: return "MFA Renewal Requested"
            case 0: return "MFA Not Registered Yet"
            default: return "No Action Applicable"
        }
    }

    const getChipLabel = (value: number) => {
        switch (value) {
            case 1: return <strong>{"Active"}</strong>
            case -1: return <strong>{"Renew Due"}</strong>
            case 0: return <strong>{"Not Registered Yet"}</strong>
            default: return <strong>{"Unknown"}</strong>
        }
    }

    const getAccessList = (accesses: { appName: string, enabled: boolean }[]): React.ReactElement[] => {
        return accesses.map((access: { appName: string, enabled: boolean }) => <Chip color={access.enabled ? "success" : "error"} variant="outlined" size="small" label={access.appName} key={access.appName} sx={{ m: 0.5 }} />)
    }

    React.useEffect(() => {
        getApps()
    }, [])

    return <>
        <Grid container spacing={2}>
            <Grid item sm />
            <Grid item sm={5}>
                <Typography variant="h5" color={"primary.main"} marginBottom={2} marginTop={2} align="center">All Registered Users</Typography>

                {errorMessage && <Typography variant="subtitle2" color="error.main" marginBottom={2} marginTop={2} align="center">{errorMessage}</Typography>}

                <div style={{ textAlign: 'center' }}>
                    {loading && <CircularProgress color="secondary" />}
                </div>
            </Grid >
            <Grid item sm />
        </Grid >
        {!loading && users && <Container>
            <CustomPaper elevation={6}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">Email ID</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">MFA Status</TableCell>
                                <TableCell align="center">Last MFA Toggle Timestamp</TableCell>
                                <TableCell align="center">Access to Applications</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(users)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((email: string, index: number) => <TableRow hover tabIndex={-1} key={users[email]._id}>
                                    <TableCell align="center">
                                        <Typography>{String(index + 1)}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>{email}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>{users[email].name}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip color={getChipColor(users[email].mfa.enabled)} variant="outlined" size="small" label={getChipLabel(users[email].mfa.enabled)} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>{users[email].mfa.lastToggleTimestamp}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {getAccessList(users[email].accesses)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="outlined" color="warning" disabled={users[email].mfa.enabled != 1} onClick={() => requestMFARenewal(users[email]._id, email)}>{getRenewalButtonText(users[email].mfa.enabled)}</Button>
                                    </TableCell>
                                </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={Object.keys(users).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </CustomPaper>
        </Container>}
    </>
}

export default ManageUsers