import { createContext, useContext, useReducer } from "react"
import LoginStatus from '../enums/login-status.enum'
import AuthContextInfo from "../interfaces/auth-context-info"

const AuthContext = createContext<AuthContextInfo>({ state: { status: LoginStatus.CHECKING, email: '' }, login: () => { }, logout: () => { }, getToken: () => '', getUsername: () => '', initialize: () => { } })

const AuthProvider = ({ children }: any) => {

    const getToken = (): string => localStorage.getItem('token') || ''

    const decodeUserFromToken = (): any => JSON.parse(window.atob(getToken().split('.')[1]))

    const isLoggedIn = (): boolean => {
        try {
            decodeUserFromToken()
            return true;
        } catch (e) {
            return false;
        }
    }

    const getUsername = (): string => isLoggedIn() ? decodeUserFromToken().username : ''

    const [state, dispatch] = useReducer
        (
            (state: any, action: { status: LoginStatus, email: string }) => {
                return action
            },
            isLoggedIn() ? { status: LoginStatus.LOGGED_IN, email: getUsername() } : { status: LoginStatus.CHECKING, email: '' }
        )

    const logout = () => {
        localStorage.removeItem('token')
        dispatch({ status: LoginStatus.LOGGED_OUT, email: '' })
    }

    const login = (token: string | null) => {
        localStorage.setItem('token', token || '')
        dispatch({ status: LoginStatus.LOGGED_IN, email: getUsername() })
    }

    const initialize = () => {
        if (isLoggedIn())
            dispatch({ status: LoginStatus.LOGGED_IN, email: getUsername() })
        else
            dispatch({ status: LoginStatus.LOGGED_OUT, email: '' })
    }

    return <AuthContext.Provider value={{ state, login, logout, getToken, getUsername, initialize }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext);
}

export default AuthProvider;