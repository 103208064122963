import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const Home = () => {

    const navigate = useNavigate()

    return <>
        <Grid container spacing={4}>
            <Grid item sm sx={{ display: { xs: 'none', sm: 'none', md: 'inherit' } }} />
            <Grid item sm={3} width="100%">
                <Card elevation={8}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div" color="secondary">
                            Add New Application
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Add a New Application to Use
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="outlined" size="small" color="success" sx={{ ml: 'auto' }} onClick={() => navigate('/applications/add')}>ADD</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item sm={3} width="100%">
                <Card elevation={8}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div" color="secondary">
                            View Applications
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            View All Applications available
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="outlined" size="small" color="success" sx={{ ml: 'auto' }} onClick={() => navigate('/applications/view')}>VIEW</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item sm={3} width="100%">
                <Card elevation={8}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div" color="secondary">
                            Manage Application
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Manage All Attributes of an Application
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="outlined" size="small" color="success" sx={{ ml: 'auto' }} onClick={() => navigate('/applications/manage/search')}>MANAGE</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item sm sx={{ display: { xs: 'none', sm: 'none', md: 'inherit' } }} />
        </Grid>
        {/* <Box sx={{ display: { xs: 'none', sm: 'none', md: 'inherit' } }}> */}
            <br />
        {/* </Box> */}
        <Grid container spacing={4}>
            <Grid item sm sx={{ display: { xs: 'none', sm: 'none', md: 'inherit' } }} />
            <Grid item sm={3} width="100%">
                <Card elevation={8}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div" color="secondary">
                            Manage Users
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            View accesses of Users across Applications
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="outlined" size="small" color="success" sx={{ ml: 'auto' }} onClick={() => navigate('/users/manage')}>MANAGE</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item sm={3} width="100%">
                <Card elevation={8}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div" color="secondary">
                            Manage Users for Application
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            Manage Various Attributes for Users of an Application
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="outlined" size="small" color="success" sx={{ ml: 'auto' }} onClick={() => navigate('/users/manage/search')}>MANAGE</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item sm sx={{ display: { xs: 'none', sm: 'none', md: 'inherit' } }} />
        </Grid>
    </>
}

export default Home