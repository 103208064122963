import { Outlet, Route, Routes } from 'react-router-dom'
import AddNewApplication from '../applications/AddNewApplication'
import ManageApplication from '../applications/ManageApplication'
import ViewApplications from '../applications/ViewApplications'
import AuthGuard from '../auth-guard/AuthGuard'
import Login from '../auth/Login'
import Home from '../home/Home'
import ManageUsers from '../users/ManageUsers'
import ManageUsersForApplication from '../users/ManageUsersForApplication'

const Routing = () => {
    return <><Routes>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="applications">
                <Route path="view" element={<AuthGuard component={ViewApplications} />} />
                <Route path="manage/:appId" element={<AuthGuard component={ManageApplication} />} />
                <Route path="add" element={<AuthGuard component={AddNewApplication} />} />
            </Route>
            <Route path="users">
                <Route path="manage" element={<AuthGuard component={ManageUsers} />} />
                <Route path="manage/:appId" element={<AuthGuard component={ManageUsersForApplication} />} />
            </Route>
        </Routes>
        <Outlet />
    </>
}

export default Routing